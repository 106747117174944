<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-05-10 11:08:26
 * @ Description: Component for upserting and displaying work payment milestones.
 -->

<template>
    <v-row>
        <v-col
            class="os-15-sb"
            cols="12"
            style="position: relative;"
        >
            Payment Milestones
            <v-btn
                icon
                style="position: absolute; right: 12px; top: 5px;"
                @click="refreshPaymentMilestones"
                v-if="canRefreshPaymentMilestones"
            >
                <v-icon>
                    {{ mdiRefresh }}
                </v-icon>
            </v-btn>
        </v-col>

        <!-- Desktop -->
        <v-col
            cols="12"
            v-if="$vuetify.breakpoint.mdAndUp"
        >
            <v-row
                align="center"
            >
                <!-- Budget -->
                <v-col
                    class="os-13-sb py-0 pb-2"
                    cols="12"
                    v-if="editMode"
                >
                    <span>Budget: <span style="color: #2E567A;">{{ currency.symbol }}{{ budget }}</span></span>
                </v-col>
                <v-col
                    class="os-13-sb py-0 pb-2"
                    cols="4"
                >
                    <span v-if="!editMode">Budget: <span style="color: #2E567A;">{{ currency.symbol }}{{ budget }}</span></span>
                    <span v-else>
                        <v-select
                            dense
                            outlined
                            color="#2E567A"
                            class="os-13-r"
                            background-color="white"
                            hide-details
                            v-model="currency"
                            placeholder="Currency"
                            :items="currencies"
                            return-object
                            item-text="name"
                            item-value="id"
                            @input="currencyChange"
                            :readonly="currencies.length === 1 && currency.id !== null"
                        >
                            <template v-slot:selection="data">
                                {{ data.item.currency }} ({{ data.item.symbol }})
                            </template>
                        </v-select>
                    </span>
                    <div v-if="!editMode">({{ currency.name }})</div>
                </v-col>
                <!-- Budget -->

                <!-- Payment Type -->
                <!-- <v-col
                    class="flex-shrink-0 flex-grow-1 py-0 pb-2"
                >
                    <v-radio-group
                        row
                        v-model="paymentTypeRadio"
                        hide-details
                        style="margin-top: 0px;"
                        class="ma-auto"
                        :disabled="!editMode"
                        @change="paymentOptionChange"
                    >
                        <v-radio
                            v-for="(item, index) in paymentOptions"
                            :key="index"
                            :label="item.name"
                            color="#2E567A"
                            class="blue--text--xxs mb-1"
                            :ripple="false"
                            :value="item.id"
                        />
                    </v-radio-group>
                </v-col> -->
                <!-- Payment Type -->

                <!-- No Milestones -->
                <v-col
                    cols="12"
                    v-if="paymentMilestonesToEdit.length < 1"
                    class="text-center"
                >
                    <span
                        class="os-12-r"
                        style="color: rgba(0, 0, 0, 0.38);"
                    >
                        No milestones added
                    </span>
                </v-col>
                <!-- No Milestones -->

                <!-- Milestones -->
                <v-col
                    cols="12"
                    v-for="(item, index) in paymentMilestonesToEdit"
                    :key="index"
                    class="py-2"
                >
                    <v-form
                        :ref="`milestoneForm_` + index"
                        v-model="item.validMilestoneForm"
                    >
                        <v-row
                            align="center"
                        >
                            <v-col
                                cols="4"
                                class="os-13-sb py-2"
                            >
                                Name
                                <br />
                                <v-text-field
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    v-model="item.name"
                                    v-if="editMode"
                                    :rules="textRules"
                                />
                                <div
                                    v-else
                                    class="os-13-r py-0"
                                    style="color: #2E567A;"
                                >
                                    {{ item.name }}
                                </div>
                            </v-col>
                            <v-col
                                cols="4"
                                class="os-13-sb py-2"
                            >
                                Amount
                                <br />
                                <v-text-field
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    v-model="item.amount"
                                    v-if="editMode"
                                    :prefix="currency.symbol"
                                    type="number"
                                    :rules="amountRules"
                                />
                                <div
                                    v-else
                                    class="os-13-r py-0"
                                    style="color: #2E567A;"
                                >
                                    {{ currency.symbol }}{{ item.amount }}
                                </div>
                            </v-col>
                            <v-col
                                cols="4"
                                class="os-13-sb py-2"
                                style="position: relative;"
                            >
                                <!-- Delete Button -->
                                <v-btn
                                    icon
                                    @click="confirmDeleteMilestone(item, index)"
                                    small
                                    style="position: absolute; right: 12px;"
                                    class="mt-n1"
                                    v-if="editMode"
                                >
                                    <v-icon
                                        size="20"
                                        color="black"
                                    >
                                        {{ mdiDeleteOutline }}
                                    </v-icon>
                                </v-btn>
                                <!-- Delete Button -->

                                Date
                                <br />
                                <v-menu
                                    v-model="item.milestoneDateMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-if="editMode"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            v-model="item.date"
                                            :rules="dateRules"
                                            v-bind="attrs"
                                            v-on="on"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="item.date"
                                        no-title
                                        scrollable
                                        color="#2E567A"
                                        @input="item.milestoneDateMenu = false"
                                    />
                                </v-menu>
                                <div
                                    v-else
                                    class="os-13-r py-0"
                                    style="color: #2E567A;"
                                >
                                    {{ item.date }}
                                </div>
                            </v-col>

                            <!-- Pay Button -->
                            <v-col
                                cols="12"
                                class="os-13-sb py-2 text-right"
                                align-self="center"
                                v-if="paymentTypeRadio === 1 && !editMode && canPayPaymentMilestones"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#069B34"
                                    v-if="!editMode"
                                    small
                                    :disabled="item.is_paid === 1"
                                    @click="payMilestone(item)"
                                >
                                    <span v-if="!item.is_paid">Pay Now</span>
                                    <span v-else>Paid</span>
                                    <v-icon
                                        v-if="item.is_paid"
                                        class="ml-1"
                                    >
                                        {{ mdiCheckCircleOutline }}
                                    </v-icon>
                                </v-btn>
                            </v-col>

                            <v-col
                                cols="12"
                                class="os-13-sb py-2 text-right"
                                align-self="center"
                                v-if="paymentTypeRadio === 1 && !editMode && canViewPaidPaymentMilestones"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#069B34"
                                    v-if="!editMode"
                                    small
                                    disabled
                                >
                                    <span v-if="!item.is_paid">Not Paid</span>
                                    <span v-else>Paid</span>
                                    <v-icon
                                        v-if="item.is_paid"
                                        class="ml-1"
                                    >
                                        {{ mdiCheckCircleOutline }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <!-- Pay Button -->
                        </v-row>
                    </v-form>
                </v-col>
                <!-- Milestones -->
            </v-row>
        </v-col>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-col
            cols="12"
            v-else-if="$vuetify.breakpoint.smAndDown"
        >
            <v-row
                align="center"
            >
                <!-- Budget -->
                <v-col
                    class="os-13-sb py-0 pb-2"
                    cols="6"
                    sm="12"
                    v-if="editMode"
                >
                    <span>Budget: <span style="color: #2E567A;">{{ currency.symbol }}{{ budget }}</span></span>
                </v-col>
                <v-col
                    class="os-13-sb"
                    :cols="editMode ? 6 : 12"
                    :sm="editMode ? 5 : 3"
                >
                    <span v-if="!editMode">Budget: <span style="color: #2E567A;">{{ currency.symbol }}{{ budget }}</span></span>
                    <span v-else>
                        <v-select
                            dense
                            outlined
                            color="#2E567A"
                            class="os-13-r"
                            background-color="white"
                            hide-details
                            v-model="currency"
                            placeholder="Currency"
                            :items="currencies"
                            return-object
                            item-text="name"
                            item-value="id"
                            @input="currencyChange"
                            :readonly="currencies.length === 1 && currency.id !== null"
                        >
                            <template v-slot:selection="data">
                                {{ data.item.currency }} ({{ data.item.symbol }})
                            </template>
                        </v-select>
                    </span>
                    <div v-if="!editMode">({{ currency.name }})</div>
                </v-col>
                <!-- Budget -->

                <!-- Payment Options -->
                <!-- <v-col
                    class="py-0 pb-1"
                    :class="$vuetify.breakpoint.sm ? 'flex-shrink-0 flex-grow-1' : ''"
                    :cols="$vuetify.breakpoint.sm ? '' : 12"
                >
                    <v-radio-group
                        row
                        v-model="paymentTypeRadio"
                        hide-details
                        style="margin-top: 0px;"
                        class="ma-auto"
                        :disabled="!editMode"
                        @change="paymentOptionChange"
                    >
                        <v-radio
                            v-for="(item, index) in paymentOptions"
                            :key="index"
                            :label="item.name"
                            color="#2E567A"
                            class="blue--text--xxs mb-1"
                            :ripple="false"
                            :value="item.id"
                        />
                    </v-radio-group>
                </v-col> -->
                <!-- Payment Options -->

                <!-- No Milestones -->
                <v-col
                    cols="12"
                    v-if="paymentMilestonesToEdit.length < 1"
                    class="text-center"
                >
                    <span
                        class="os-12-r"
                        style="color: rgba(0, 0, 0, 0.38);"
                    >
                        No milestones added
                    </span>
                </v-col>
                <!-- No Milestones -->

                <!-- Milestones -->
                <v-col
                    cols="12"
                    sm="6"
                    v-for="(item, index) in paymentMilestonesToEdit"
                    :key="index"
                    class="pa-4"
                >
                    <v-form
                        :ref="`milestoneForm_` + index"
                        v-model="item.validMilestoneForm"
                    >
                        <v-row
                            align="center"
                            style="border: 1px solid #707070;"
                            class="rounded"
                        >
                            <v-col
                                cols="6"
                                class="os-13-sb py-2 pr-1"
                            >
                                Name
                                <br />
                                <v-text-field
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    v-model="item.name"
                                    v-if="editMode"
                                    :rules="textRules"
                                />
                                <div
                                    v-else
                                    class="os-13-r py-0"
                                    style="color: #2E567A;"
                                >
                                    {{ item.name }}
                                </div>
                            </v-col>
                            <v-col
                                cols="6"
                                class="os-13-sb py-2 pl-1"
                            >
                                Amount
                                <br />
                                <v-text-field
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    v-model="item.amount"
                                    v-if="editMode"
                                    :prefix="currency.symbol"
                                    type="number"
                                    :rules="amountRules"
                                />
                                <div
                                    v-else
                                    class="os-13-r py-0"
                                    style="color: #2E567A;"
                                >
                                    {{ currency.symbol }}{{ item.amount }}
                                </div>
                            </v-col>
                            <v-col
                                cols="6"
                                class="os-13-sb py-2 pr-1"
                                style="position: relative;"
                            >
                                Date
                                <br />
                                <v-menu
                                    v-model="item.milestoneDateMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-if="editMode"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            v-model="item.date"
                                            :rules="dateRules"
                                            v-bind="attrs"
                                            v-on="on"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="item.date"
                                        no-title
                                        scrollable
                                        color="#2E567A"
                                        @input="item.milestoneDateMenu = false"
                                    />
                                </v-menu>
                                <div
                                    v-else
                                    class="os-13-r py-0"
                                    style="color: #2E567A;"
                                >
                                    {{ item.date }}
                                </div>
                            </v-col>

                            <!-- Pay Button -->
                            <v-col
                                cols="6"
                                class="os-13-sb py-2 text-right"
                                align-self="center"
                                v-if="paymentTypeRadio === 1 && !editMode && canPayPaymentMilestones"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#069B34"
                                    v-if="!editMode"
                                    small
                                    :disabled="item.is_paid === 1"
                                    @click="payMilestone(item)"
                                >
                                    <span v-if="!item.is_paid">Pay Now</span>
                                    <span v-else>Paid</span>
                                    <v-icon
                                        v-if="item.is_paid"
                                        class="ml-1"
                                    >
                                        {{ mdiCheckCircleOutline }}
                                    </v-icon>
                                </v-btn>
                            </v-col>

                            <v-col
                                cols="6"
                                class="os-13-sb py-2 text-right"
                                align-self="center"
                                v-if="paymentTypeRadio === 1 && !editMode && canViewPaidPaymentMilestones"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#069B34"
                                    v-if="!editMode"
                                    small
                                    disabled
                                >
                                    <span v-if="!item.is_paid">Not Paid</span>
                                    <span v-else>Paid</span>
                                    <v-icon
                                        v-if="item.is_paid"
                                        class="ml-1"
                                    >
                                        {{ mdiCheckCircleOutline }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <!-- Pay Button -->

                            <v-col
                                cols="6"
                                class="py-2 text-right"
                                v-else-if="editMode"
                            >
                                <!-- Delete Button -->
                                <v-btn
                                    icon
                                    @click="confirmDeleteMilestone(item, index)"
                                    small
                                >
                                    <v-icon
                                        size="20"
                                        color="black"
                                    >
                                        {{ mdiDeleteOutline }}
                                    </v-icon>
                                </v-btn>
                                <!-- Delete Button -->
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
                <!-- Milestones -->
            </v-row>
        </v-col>
        <!-- Mobile -->

        <!-- Add Button -->
        <v-col
            cols="12"
            class="text-right"
            v-if="canEditPaymentMilestones"
        >
            <v-btn
                class="white--text no-uppercase rounded-lg os-13-sb mr-1"
                color="#069B34"
                v-if="editMode && paymentMilestonesToEdit && paymentMilestonesToEdit.length > 0"
                :disabled="disablePaymentMilestonesSaveButton"
                @click="savePaymentMilestones"
            >
                Save
            </v-btn>
            <v-btn
                class="white--text no-uppercase rounded-lg os-13-sb"
                color="#FFA838"
                v-if="editMode"
                @click="addPaymentMilestone"
            >
                <span v-if="!$store.state.general.customBreakpoints.xxs">Add Milestone</span>
                <v-icon
                    class="ml-1"
                >
                    {{ mdiPlusCircleOutline }}
                </v-icon>
            </v-btn>
        </v-col>
        <!-- Add Button -->

        <!-- Payment T's & C's -->
        <!-- <v-col
            cols="12"
            class="os-12-sb text-center"
        >
            <div
                style="border: 1px solid #707070;"
                class="rounded"
            >
                Payment Terms & Conditions
            </div>
        </v-col> -->
        <!-- Payment T's & C's -->

        <!-- Confirm Delete Overlay -->
        <general-confirm-delete-overlay-component
            :showDeleteConfirmationOverlayBoolean="showDeleteConfirmationOverlayBoolean"
            :itemToDeleteType="'Milestone'"
            @showDeleteConfirmationOverlay="showDeleteConfirmationOverlay"
        />
        <!-- Confirm Delete Overlay -->

        <!-- Payment Overlay -->
        <payment-overlay-component
            :showPaymentOverlayBoolean="showPaymentOverlayBoolean"
            :invoiceData="invoiceData"
            @showPaymentOverlay="showPaymentOverlay"
        />
        <!-- Payment Overlay -->
    </v-row>
</template>
<script>
    import { mdiDeleteOutline, mdiPlusCircleOutline, mdiCheckCircleOutline, mdiContentSaveOutline, mdiRefresh } from '@mdi/js'
    import { GeneralInvoiceController, WorkJobMilestoneController, LookupLocationController, LookupPaymentController } from '@/logic/controller/index.controller.js'
    import GeneralConfirmDeleteOverlayComponent from '@/overlays/general/GeneralConfirmDeleteOverlayComponent.vue'
    import PaymentOverlayComponent from '@/overlays/payment/PaymentOverlayComponent.vue'

    export default {
        name: 'GeneralWorkPaymentMilestonesComponent',

        components: {
            GeneralConfirmDeleteOverlayComponent,
            PaymentOverlayComponent
        },

        props: {
            canEditPaymentMilestones: {
                type: Boolean,
                default: false,
            },

            canPayPaymentMilestones: {
                type: Boolean,
                default: false
            },

            canRefreshPaymentMilestones: {
                type: Boolean,
                default: false
            },

            canViewPaidPaymentMilestones: {
                type: Boolean,
                default: false
            },

            editMode: {
                type: Boolean,
                default: true
            },

            paymentMilestones: {
                type: Array,
                default: () => {
                    return []
                }
            },

            validatePaymentMilestoneDates: {
                type: Boolean,
                default: false
            }
        },

        watch: {
            paymentMilestones: {
                async handler (value) {
                    if (value && value.length > 0) {
                        for (let index = 0; index < value.length; index++) {
                            const milestone = value[index]
                            milestone.validMilestoneForm = true

                            if (this.currencies && this.currencies.length > 0) {
                                const currencyIndex = this.currencies.findIndex(itemToFind => itemToFind.id === milestone.currency.id)
                                if (currencyIndex > -1) this.currency = this.currencies[currencyIndex]
                            }

                            this.paymentTypeRadio = milestone.payment_option.id
                        }
                    }

                    this.paymentMilestonesToEdit = value
                    this.validatePaymentMilestones()
                },
                immediate: true,
                deep: true
            },

            disablePaymentMilestonesSaveButton: {
                handler (value) {
                    this.$emit('disablePaymentMilestonesSaveButton', value)
                },
                immediate: true
            },

            paymentMilestonesToEdit: {
                handler (value) {
                    this.$emit('getPaymentMilestonesInMemory', value)
                },
                deep: true
            }
        },

        computed: {
            budget () {
                let budget = 0

                if (this.paymentMilestonesToEdit && this.paymentMilestonesToEdit.length > 0) {
                    for (let index = 0; index < this.paymentMilestonesToEdit.length; index++) {
                        const milestone = this.paymentMilestonesToEdit[index]
                        if (milestone.amount) {
                            if (milestone.amount.replace(/\s/g, '') !== '' && Number(milestone.amount) > 0) budget += Number(milestone.amount)
                        }
                    }
                }

                return budget.toFixed(2)
            },

            textRules () {
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field',
                    v => v.length <= 50 || 'Max 50 characters'
                ]
            },

            amountRules () {
                if (this.paymentTypeRadio === 1) {
                    return [
                        v => !!v || 'Required field',
                        v => v > 0 || 'Amount > 0'
                    ]
                } else {
                    return [
                        v => !!v || 'Required field'
                    ]
                }
            },

            dateRules () {
                let today = new Date()
                today = today.toISOString().split('T')[0]

                if (this.paymentTypeRadio === 1) {
                    if (!this.validatePaymentMilestoneDates) {
                        return [
                            v => !!v || 'Required field',
                            v => v.replace(/\s/g, '') !== '' || 'Invalid date'
                        ]
                    } else {
                        return [
                            v => !!v || 'Required field',
                            v => v.replace(/\s/g, '') !== '' || 'Invalid date',
                            v => new Date(v.replace(/-/g, '/')).getTime() >= new Date(today.replace(/-/g, '/')).getTime() || 'Date > today'
                        ]
                    }
                } else {
                    return [
                        v => !!v || 'Required field',
                        v => v.replace(/\s/g, '') !== '' || 'Invalid date'
                    ]
                }
            }
        },

        data () {
            return {
                mdiDeleteOutline,
                mdiPlusCircleOutline,
                mdiCheckCircleOutline,
                mdiContentSaveOutline,
                mdiRefresh,
                paymentMilestonesToEdit: [],
                currency: {
                    id: 144,
                    symbol: '$',
                    name: 'US Dollar'
                },
                paymentTypeRadio: 2,
                lookupLocationController: null,
                lookupPaymentController: null,
                generalInvoiceController: null,
                workJobMilestoneController: null,
                currencies: [],
                paymentOptions: [],
                showDeleteConfirmationOverlayBoolean: false,
                milestoneToDelete: null,
                disablePaymentMilestonesSaveButton: false,
                invoiceData: {
                    item: {},
                    invoice: {}
                },
                showPaymentOverlayBoolean: false
            }
        },

        beforeMount () {
            this.lookupLocationController = new LookupLocationController()
            this.lookupPaymentController = new LookupPaymentController()
            this.generalInvoiceController = new GeneralInvoiceController()
            this.workJobMilestoneController = new WorkJobMilestoneController()
        },

        async mounted () {
            await this.getCurrencies()
            await this.getPaymentOptions()
        },

        methods: {
            async getCurrencies () {
                try {
                    this.currencies = await this.lookupLocationController.getCurrencies()

                    if (this.currencies.length === 1) this.currency = this.currencies[0]

                    if (this.paymentMilestonesToEdit.length > 0) {
                        const currencyIndex = this.currencies.findIndex(itemToFind => itemToFind.id === this.paymentMilestonesToEdit[0].currency.id)
                        if (currencyIndex > -1) this.currency = this.currencies[currencyIndex]
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getPaymentOptions () {
                try {
                    this.paymentOptions = await this.lookupPaymentController.getPaymentOptions()

                    if (this.paymentMilestonesToEdit.length > 0) {
                        this.paymentTypeRadio = this.paymentMilestonesToEdit[0].payment_option.id
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            currencyChange () {
                if (this.paymentMilestonesToEdit && this.paymentMilestonesToEdit.length > 0) {
                    for (let index = 0; index < this.paymentMilestonesToEdit.length; index++) {
                        const milestone = this.paymentMilestonesToEdit[index]
                        milestone.currency = this.currency
                    }
                }
            },

            paymentOptionChange () {
                if (this.paymentMilestonesToEdit && this.paymentMilestonesToEdit.length > 0) {
                    for (let index = 0; index < this.paymentMilestonesToEdit.length; index++) {
                        const milestone = this.paymentMilestonesToEdit[index]
                        milestone.payment_option = { id: this.paymentTypeRadio }
                    }
                }

                this.validatePaymentMilestones()
            },

            addPaymentMilestone () {
                var emptyMilestone = {
                    user_id: null,
                    milestone_id: null,
                    milestone_item_id: null,
                    name: 'Payment ' + (this.paymentMilestonesToEdit.length + 1),
                    amount: Number(10).toFixed(2),
                    date: new Date().toISOString().split('T')[0],
                    is_paid: 0,
                    currency: this.currency,
                    milestoneDateMenu: false,
                    validMilestoneForm: false,
                    payment_option: { id: this.paymentTypeRadio }
                }

                if (this.paymentMilestonesToEdit.length === 0) emptyMilestone.name = 'Deposit'

                this.paymentMilestonesToEdit.push(emptyMilestone)
            },

            confirmDeleteMilestone (milestone, milestoneIndex) {
                if (milestone.milestone_item_id) {
                    this.milestoneToDelete = milestone
                    this.showDeleteConfirmationOverlay(true)
                } else this.paymentMilestones.splice(milestoneIndex, 1)
            },

            showDeleteConfirmationOverlay (value, confirmDelete) {
                this.showDeleteConfirmationOverlayBoolean = value

                if (confirmDelete) this.removePaymentMilestone(this.milestoneToDelete)
            },

            removePaymentMilestone (milestone) {
                this.$emit('removePaymentMilestone', milestone)
                this.milestoneToDelete = null
            },

            async payMilestone (milestone) {
                try {
                    await this.workJobMilestoneController.pay(milestone)

                    this.$store.commit('setSnackbar', {
                        text: 'Paid successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    if (error.errorCode) {
                        this.invoiceData = error.invoiceData
                        this.showPaymentOverlay(true)
                    }
                    else {
                        this.$store.commit('setSnackbar', {
                            text: error,
                            show: true
                        })
                    }
                }
            },

            showPaymentOverlay (value, confirmPayment, selectedCard) {
                this.showPaymentOverlayBoolean = value

                if (confirmPayment) this.payInvoice(this.invoiceData, selectedCard)
            },

            async payInvoice (invoice, card) {
                try {
                    await this.generalInvoiceController.pay(invoice.invoice.id, card.card_id)
                    this.refreshPaymentMilestones()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            savePaymentMilestones () {
                this.$emit('savePaymentMilestones', this.paymentMilestonesToEdit)
            },

            refreshPaymentMilestones () {
                this.$emit('refreshPaymentMilestones')
            },

            validatePaymentMilestones () {
                if (!this.paymentMilestonesToEdit || this.paymentMilestonesToEdit.length < 1 && this.paymentTypeRadio === 1) this.disablePaymentMilestonesSaveButton = true
                else if (!this.paymentMilestonesToEdit || this.paymentMilestonesToEdit.length < 1 && this.paymentTypeRadio === 2) this.disablePaymentMilestonesSaveButton = false
                else {
                    for (let index = 0; index < this.paymentMilestonesToEdit.length; index++) {
                        this.$nextTick(() => {
                            this.$refs[`milestoneForm_${index}`][0].validate()
                        })
                    }

                    for (let index = 0; index < this.paymentMilestonesToEdit.length; index++) {
                        this.disablePaymentMilestonesSaveButton = true

                        const milestone = this.paymentMilestonesToEdit[index]

                        let today = new Date()
                        today = today.toISOString().split('T')[0]
                        
                        if (!milestone.name) break
                        else if (milestone.name.replace(/\s/g, '') === '') break

                        else if (!milestone.date) break
                        else if (milestone.date.replace(/\s/g, '') === '') break
                        else if (this.paymentTypeRadio === 1 && this.validatePaymentMilestoneDates && (new Date(milestone.date.replace(/-/g, '/')).getTime() < new Date(today.replace(/-/g, '/')).getTime())) break

                        else if (!milestone.amount) break
                        else if (milestone.amount.replace(/\s/g, '') === '') break
                        else if (Number(milestone.amount) <= 0) break

                        this.disablePaymentMilestonesSaveButton = false
                    }
                }
            }
        }
    }
</script>
<style scoped>
    .blue--text--xxs /deep/ label {
        font-size: 13px;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
    }
</style>